<template>
	<div>
		<img class="img_bg" src="../../asset/tongYi/tongYi01.jpg" alt="img_bg" />
		<img class="img_bg" src="../../asset/tongYi/tongYi02.jpg" alt="img_bg" />
		<img class="img_bg" src="../../asset/tongYi/tongYi03.png" alt="img_bg" />
		<div class="gao"></div>
		<div class="bnt_vi">
			<div class="btn01" @click="onClick(1)">立即购买¥6</div>
			<div class="btn02" @click="goVip">了解刷题会员详情 <img src="../../asset/shuci/icon.png" alt=""> </div>
		</div>
	</div>
</template>

<script>
	export default {
		methods: {
			goVip(){
				this.$router.push({
					name: 'vip',
				})
			},
			onClick(e) {
				let u = navigator.userAgent;
				let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1;
				if (isAndroid) {
					window.android.payment(e)
				} else {
					window.webkit.messageHandlers.payment.postMessage(e)
				}
			},
		}
	}
</script>

<style scoped="scoped">
	
	.img_bg {
		width: 100%;
		display: block;
	}
	.bnt_vi{
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 98px;
		display: flex;
	}
	.gao{
		height: 98px;
	}
	.bnt_vi img{
		width: 28px;
		height: 28px;
		margin-left: 10px;
	}
	.bnt_vi div{
		font-size: 28px;
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	
	.btn01{
		width: 34.6%;
		background-color: #FFB343;
	}
	.btn02{
		width: 67.4%;
		background-color: #202E61;
	}
</style>
